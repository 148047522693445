<template>
	 <b-modal ref="modal" hide-footer size="xl">
        <template v-slot:modal-title>
       		{{ $t("invoice.cc_avoir_non_solde") }}
        </template>

       	<router-link :to="{ name: 'tiersFiche', params: { tiers_id: tiers_id }}">
			<i><font-awesome-icon :icon="['fal', 'link']"/></i> {{ tiers_rs }}
        </router-link>
       	<h3>{{ $t("invoice.montant_cc") }} : {{ current_account }}</h3>
        <CustomTable
			id_table="invoice_to_solde_cc"
			ref="invoice_to_solde"
			:items="avoir"
			primaryKey="invoice_id"
			:hide_if_empty="true"
        />
    </b-modal>
</template>

<script type="text/javascript">
    import Tiers from "@/mixins/Tiers.js"
    import Navigation from "@/mixins/Navigation.js"
    import Payment from "@/mixins/Payment.js"
    import { EventBus } from "EventBus"

	export default {
		name: 'ModalAvoirCC',
		mixins: [Tiers, Navigation, Payment],
		props: ['callback'],
		data () {
			return {
				tiers_id: null,
				accountingplan_id: null,
				avoir: [],
				current_account: 0,
				tiers_rs: ''
			}
		},

		methods: {
			async init_component(tiers_id, tiers_rs, accountingplan_id) {
				this.tiers_id = tiers_id
				this.tiers_rs = tiers_rs
				this.accountingplan_id = accountingplan_id

				const invoices = await this.loadAllInvoicesByTiers(tiers_id)
				this.avoir = invoices.filter(invoice => invoice.invoice_balance < 0 && invoice.invoice_accountingplan == accountingplan_id)

				let current_account = await this.loadCurrentAccount(tiers_id, true)
				current_account = current_account[accountingplan_id]
				if(current_account) {
					this.current_account = await this.priceFormat(current_account.balance / 100, current_account.currency, true)
				}
				// Ne s'affiche pas si pas d'avoirs ET que l'account balance est à 0
				if(this.avoir.length == 0 && (current_account == undefined || (current_account && current_account.balance === 0))) {
					this.$refs.modal.hide()
					this.callback()
				} else {
					this.$refs.modal.show()
				}
			},
		},

		components: {
            CustomTable: () => import('GroomyRoot/components/Table/CustomTable'),
		}
	}

</script>